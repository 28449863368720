import React from 'react'
import styled from 'styled-components'

import { Wrapper } from './styles'

const BannerText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  text-align: center;
  text-transform: none;
  & a {
    text-decoration: underline;
  }
`

export const Banner = (): JSX.Element => {
  return (
    <Wrapper>
      <BannerText>
        🎉 Curio is moving to the Bitcoin blockchain! #RWA on Bitcoin is going live soon! 👉 &nbsp;
        <a href="https://bitspectrum.capitaldex.exchange" target="_blank" rel="noreferrer">
          Launch BitSpectrum
        </a>
      </BannerText>
      <BannerText>
        🥳 L2TON Liquid Staking chain (beta) is already live! 👉 &nbsp;
        <a href="https://bridge.capitaldex.exchange/" target="_blank" rel="noreferrer">
          Stake TON to L2TON
        </a>
      </BannerText>
      <BannerText>
        🔥 Capital DEX is already deployed on the TON network and has a Telegram Mini App! 👉{' '}
        <a href="https://t.me/CapitalDexBot" target="_blank" rel="noreferrer">
          Launch Capital DEX Mini App
        </a>
      </BannerText>
    </Wrapper>
  )
}

const BannerWrapper = styled.div`
  width: 100%;
  background-color: var(--blue);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
`

const BannerLink = styled.a`
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  text-transform: none;
`

export const Announcement = (): JSX.Element => (
  <div style={{ background: 'var(--blue)', color: 'var(--white)', flexDirection: 'column' }}>
    <BannerWrapper>
      <BannerLink href={'https://t.me/curiocarQA'} target="_blank">
        🚨 Capital DEX and RollApp dApps are undergoing maintenance 🚨
      </BannerLink>
    </BannerWrapper>
    <BannerWrapper>
      <BannerLink href={'https://t.me/curiocarQA'} target="_blank">
        Wait for the announcement in our Telegram Channel
      </BannerLink>
    </BannerWrapper>
  </div>
)
